<template>
	<div class="main">
    <TheTopbar/>
    <button class="btn btn-secondary" @click="goBack()">back <b-badge class="text-dark" v-b-tooltip.hover title="Click to go back to the previous page">i</b-badge></button>
	<div class="main-container" >
    <div class="d-flex">
      <div class="content-wrapper">
        <div class="">
          <div v-if="isLoading" class="loading-spinner"></div>
          
            <div v-if="!isLoading">
              <div class="market-content">
                    <table >
                      <thead>
                      <tr>
                          <th>Match Type
                            <button @click="openInfo"><span v-if="showInfo">Used to determine how closely a watchlist entity must match an individual before being considered a match"</span>
                          </button>
                          </th>
                          <th>Whitelist Policy
                            <button @click="openInfo"><span v-if="showInfo">Used for eliminating match results previously determined to not be a true a match</span>
                          </button>
                          </th>
                          <th>PEP Jurisdiction
                            <button @click="openInfo"><span v-if="showInfo">Used for eliminating match results where the country of jurisdiction of the matching entity is specified in the organisation's settings (Exclude PEP Jurisdiction)</span>
                          </button>
                          </th>
                          <th>Residence Policy
                            <button @click="openInfo"><span v-if="showInfo">Used for eliminating match results where the individual and matching entity have different country of Residence. Choose which categories to apply this filter for Politically Exposed Persons, Profiles of Interest, Relative or Close Associates, or Special Interest Persons (which includes Terrorists). You may choose all, some or none</span>
                          </button>
                          </th>
                          <th>Default Country of Residence
                            <button @click="openInfo"><span v-if="showInfo">Used to assign a nominated Country of Residence to an individual, when a country cannot be identified in the individual's address data. Applicable only where the Country of Residence Policy is utilised in the matching criteria. The default country can be defined by the Compliance Officer in Administration > Organisations > Settings tab > Default Country of Residence/Operation.</span>

                            </button>
                          </th>
                         
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        <td>{{scanParam.matchType}} - {{ scanParam.closeMatchRateThreshold }}% </td>
                        <td>{{scanParam.whitelist}}</td>
                        <td>{{scanParam.isPepJurisdictionExclude}} </td>
                        <td>{{scanParam.residence}}</td>
                        <td>Not Specified</td>
                      </tr>
                    </tbody>
                    </table>
                    <table >
                      <thead>
                      <tr>
                          <th>Exclude Deceased Person
                            <button  @click="openInfo"><span v-if="showInfo">Used for eliminating match results where the person is tagged as deceased.</span>
                          </button>
                          </th>
                          <th>Web Search
                            <button @click="openInfo"><span v-if="showInfo">Additional search for adverse media on the web using Google search engine.</span>
                          </button>
                          </th>
                          <th>FATF Jurisdiction Risk
                            <button @click="openInfo"><span v-if="showInfo">Understand the jurisdictional risk of an individual based on FATF guidelines.</span>
                          </button>
                          </th>
                          <th>Watchlist
                            <button @click="openInfo"><span v-if="showInfo">Scan against selected watchlists. This selection can be changed by the Compliance Officer in Administration > Organisations > List Access tab.</span>
                          </button>
                          </th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr>
                        
                        <td>{{scanParam.excludeDeceasedPersons}}</td>
                        <td>{{scanParam.includeWebSearch}}</td>
                        <td>{{scanParam.includeJurisdictionRisk}}</td>
                        <td>
                         <table>
                          <thead>
                            <tr v-for="item in scanParam.watchLists" :key="item.id">
                              <th>{{ item }}</th>
                            </tr>
                          </thead>
                         </table>
                        </td>
                      </tr>
                    </tbody>
                    </table>
                    <button class="btn btn-secondary" v-if="scanParam.includeWebSearch == 'Yes'" @click="openWebSearch" v-b-tooltip.hover title="Click to view adverse media/ websearch result">WebSearch Results</button>
                    <div class="market-content" v-if="includeWebSearch == true">
                        <table class="table" >
                          <thead>
                            <tr>
                              <th>Web Search Results</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                            <td>
                              <table class="inside-table">
                                  <tbody>
                                          <tr v-for="item in scanResult.webSearchResults" :key="item.id">
                                            <th >{{ item.displayLink }}</th>
                                            <th>{{ item.htmlTitle }}</th>
                                            <th><a :href="item.link" target="_blank" class="ellipsis-text">{{ item.link }}</a></th>
                                          </tr>
                                  </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  </div>
            </div>
            <div v-if="!isLoading" >
                <h4 class="text-white text-uppercase">Scanned on {{ formatDate(scanParam.date)}} for {{ scanParam.firstName }} {{ scanParam.middleName }} {{ scanParam.lastName }} </h4>
                <h4 class="text-white">Match results: {{ scanResult.matchedNumber }}</h4>
                <MatchCard v-for="array in scanResult.matchedEntities" :key="array.id" :profile="array" ></MatchCard>
                <!-- <div>
                    <button @click="prevPage" :disabled="currentPage === 1" class="btn btn-light">Previous</button>
                    <span class="px-2 text-white"> {{ currentPage }} </span>
                    <button @click="nextPage" :disabled="currentPage === totalPages" class="btn btn-light">Next</button>
                </div> -->
              </div>
         </div>
      </div>
      
    </div>
    
    </div>
   
	</div>
          
</template>
        
<script>
  import moment from 'moment';
  import MatchCard from '../components/modal/Compliance/MatchCard.vue'
  import TheTopbar from '../components/TheTopbar.vue'
  
  export default {
     
      name: 'NamescreenMatches',
      components: {
        MatchCard, 
        TheTopbar
      },
      
       data: function() {
      return { 
       isLoading: false,
       showExpand: false,
       scanResults:'',
       image:'',
       scanId:'',
       scanParam:'',
       scanResult:'',
       includeWebSearch: false,
       showInfo: false,
       itemsPerPage: 3,
        currentPage: 1
      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
        // totalPages() {
        //   return Math.ceil(this.scanResult.matchedEntities.length / this.itemsPerPage);
        // },
        // paginatedData() {
        //   const start = (this.currentPage - 1) * this.itemsPerPage;
        //   const end = start + this.itemsPerPage;
        //   return this.scanResult.matchedEntities.slice(start, end);
          
        // },
     
      },
      methods:{
        nextPage() {
          if (this.currentPage < this.totalPages) {
            this.currentPage++;
          }
        },
        prevPage() {
          if (this.currentPage > 1) {
            this.currentPage--;
          }
        },
        openWebSearch(){
          this.includeWebSearch = !this.includeWebSearch
        },
        expand(profile){
          this.profile = profile
          this.isLoading = true
          this.showExpand = !this.showExpand
       
        },
        openInfo(){
          this.showInfo = !this.showInfo
        },
        goBack(){
            this.$router.go(-1);
        },
        getHistoryDetails(){   
        this.isLoading = true
          const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'vmiadmin-authz': localStorage.getItem("tokenId") 
            }, 
            url: '/ax/namescan/details/' + this.$route.params.scanId
            }
            this.axios(options)
            .then((response) => {
              console.log(response.data.scanResult.matchedEntities.resultId)
              this.scanResults = response.data
              this.scanParam = response.data.scanParam
              this.scanResult = response.data.scanResult
             
              this.isLoading = false
             
            }).catch((err) => {
                
            })
      },
       
       
       
          
      formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
          
       
           
      
          openSettings(){
          this.showSettings = !this.showSettings
        },
              
        getDecisionHistory(){
       
            const params = {
              id: this.profile.resultId
            }
            const options = {
                    method: 'GET',
                    headers: { 'content-type': 'application/x-www-form-urlencoded',
                        'vmiadmin-authz': localStorage.getItem("tokenId")  },
                    url: '/ax/namescan/decision/result',
                    params
                    };

                  this.axios(options)
                  .then((response) => { 
                  this.history = response.data
                
                })
        },
         
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
            this.axios(options)
              .then((response) => {
             
              }).catch((err) => {
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getHistoryDetails();
          // this.getDecisionHistory();
          
      }
  }
</script>
        
<style scoped lang="scss">
    * {
    box-sizing: border-box;
  }
  
  /* Create two equal columns that floats next to each other */
  .column1 {
    float: left;
    width: 30%;
    padding: 10px;
  
  }
  .column2 {
    float: left;
    width: 70%;
    padding: 10px;
  
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .btn-green{

  }
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      width: 100%;
    }
  }
  .checkbox label{
    font-size: 12px !important;
  }
.header-wrapper img{
    width: 10em;
  }
  .main-content{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin: 0 auto !important;
    padding: 20px 5px;
    width: 100%;
  }
  .card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 300px;
  margin: auto;
  text-align: center;
  font-family: arial;
}
.card img{
  width: 10rem;
}
.title {
  color: grey;
  font-size: 18px;
}
.btn-green{
  background: green;
}
.btn-red{
  background: rgb(230, 100, 100);
}

// button {
//   border: none;
//   outline: 0;
//   display: inline-block;
//   padding: 8px;
//   color: white;
//   background-color: #000;
//   text-align: center;
//   cursor: pointer;
//   width: 100%;
//   font-size: 18px;
// }

// a {
//   text-decoration: none;
//   font-size: 22px;
//   color: black;
// }

// button:hover, a:hover {
//   opacity: 0.7;
// }
  
  .market-content {
      width: 100%;
      border-radius: 10px;
      padding: 0;
      table {
        width: 100%;
        thead {
          th {
            font-size: 15px;
            color: #c5c2c2; 
            letter-spacing: 0.65px;
            padding: 1em;
            // position: sticky;
            top: 0;
            background-image: linear-gradient(
            to bottom,
            #5a5f71 29%,
            #10121C 88%
      );
            text-align: inherit;
          }
        }
  
        tbody {
          background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
      );
          td {
            color: #FFFFFF;
            letter-spacing: 0.65px;
            padding: 1em;
  
            a {
              color: #FFFFFF;
              text-decoration: none;
  
              &:hover {
                text-decoration: underline;
              }  
            }
  
            &.hash {
              a {
                color: #FFBE21;
              }
              
            }
          }
        }
      }
    }
  .form-control-short{
    border-radius: 10px;
    padding: 10px;
  }
  .content-wrapper{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    justify-content: center !important;
    min-width: 350px !important;
    margin: 10px auto;
    background-color: #053d5c;
    border-radius: 10px;
    padding: 5px;
    
  }
 
  .progress-bars {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .form-group .button{
    margin-top: 100px !important;
  }
  .icon{
    width: 2rem;
  }
  .progress-bar {
    width: 100px;
    height: 10px;
    background-color: lightgray;
    margin-right: 5px;
    border-radius: 6px;
  }
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
  }
  .enhanced{
    margin-top: 2em;
  }

.three-column {
  // display: flex;
  justify-content: space-between;
}
.modal-footer{
  border-top: none !important;
}
.form-row {
  // display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-column {
  flex-basis: calc(33.33% - 20px);
  margin-bottom: 20px;
}
form{
  padding-top: 3px;
}

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }
}
.btn .btn-checkbox{
  padding: 0px !important;
  margin: 0px !important;
}
.btn-checkbox .checkbox{
  width: 20px !important;
}
label {
  color: white;
  margin-right: 5px;
  font-size: 15px;
}
.modal-body{
  margin-left: 15px;
}

   .btn-flag {
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2;
  }
   .mobile-container {
    position: relative;
   }
   .default-flag {
      width: 33px;
      margin-right: 0.25em;
    }
    

    .country-list {
      position: absolute;
      background: #ccc;
      width: 300px;
      color: #333;
      top: 70px;
      padding: 0.5em;
      height: 13em;
      overflow: auto;
      z-index: 3;
    }
      .row {
        margin: 0 !important;
      }
        .col-sm-6 {
          display: flex;
          padding: 0.5em;
          align-items: center;
          cursor: pointer;
        }
        .col-sm-6:hover {
            background: darken(#ccc, 10%);
          }
          .country-name {
            margin-left: 0.5em;
          }

          span {
            font-size: 0.9em;
          }
   

  .flag-list-img {
    width: 33px;
  }
  .btn-cancel{
        float: right;
        background-color: #333;
        color: white;
    }

  .disabled{
  cursor: not-allowed;
}
.input-group{
  flex-wrap: nowrap;
  padding: 0;
}
.input-group .form-control{
  height: auto;
}
fieldset{
  min-height: 50px !important;
}

.legend{
   color: white !important;
    padding-top: 10px;
  }
  h1 {
  text-align: center;
  font-size: 5rem;
  }

  form {
  width: 90%;
  margin: 0 auto;
  }

  .box {
  padding: 0.1rem;
  border-radius: 6px !important;
  margin-bottom: 10px;
  }

  label {
  display: flex;
  font-size: 16px;;
  }

  input, .form-control, .form-select {
  display: inline-block;
  width: 200px;
  border-radius: 10px;

  }

  input:required:invalid, input:focus:invalid {
  background-position: right top;
  background-repeat: no-repeat;
  -moz-box-shadow: none;
  }

  input:required:valid {
  background-position: right top;
  background-repeat: no-repeat;
  }

  #masthead {
    padding: 10rem 0;
    background-size: 100%;
    /*color: #fff;*/;
  }
  .help{
    margin: 2rem;
    
  }
@media all and (min-width: 35rem) and (max-width: 60rem) {
 
    .box {
    float: left;
    width: 33%;
    }
    .box2 {
    float: left;
    width: 50%;
    }

    label {
    display: flex;
    }

    #masthead {
    }
}

@media all and (min-width:60rem) {
  
    label {
    display: inline;
    width: 150px;
    float: left;
    }
    .box2 {
    float: left;
    width: 50%;
    }
    .box {
    width: 33%;
    float: left;
    }

    input {
    width: 50%;
    }

    #masthead {
    padding: 9rem;
    }
}




   

.icon img{
  width: 2rem;
}
.card{
  background-image: linear-gradient(
            to bottom,
            #202538 29%,
            #10121C 88%
  );
    box-shadow:0 0 30px rgba(0,0,0,0.3);
    border: 1px solid transparent;
    border-radius: 12px;
    width: 100%;
    height: auto;
    text-align: center;
    color: white;
}


.verified{
  background-color: green;
  color: white;
}
.submitted{
  background-color: green !important;
  color: white;
}
.notSubmitted{
  background-color: red;
  
}
  
 
  
    .progress-bars {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    
    /* margin-bottom: 20px; */
  }
  
  .progress-bar {
    width: 100px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
  }
  
  .active {
    background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;;
    color: white !important;
  }

@media (max-width: 767px) {
  .form-column {
    flex-basis: 100%;
  }

  .progress-bar {
    width: 80px;
    height: 50px;
    background-color: lightgray;
    background-color: #053d5c !important;
    margin-right: 5px;
    border-radius: 20px !important;
    color: white !important;
    font-size: 10px;
    
    
  }
  
}
@media (max-width: 1280px) {

  
}
  
  label {
    margin-right: 5px;
  }
  

.btn-card-upload{
  width: 100%;
  height: auto;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
  background-color: #053d5c !important;
  display: flex;
  justify-content: space-between;
 
}

.indicator img{
  width: 1rem;
}
.indicator-progress img{
  width: 1rem;
}
.loading-spinner {
      border: 4px solid rgba(0, 0, 0, 0.3);
      border-top: 4px solid #007BFF; /* Change the color to your preferred color */
      border-radius: 50%;
      width: 40px;
      height: 40px;
      animation: spin 1s linear infinite;
      margin: 0 auto;
    }
    @keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
