<template>
    <div class="backdrop">
      <div class="b-modal">
          <div class="modal-header text-right">
                <h5 class="text-white">Other details</h5>
                <p @click="closeModal" class="x">x</p>
            </div>
          <div class="modalBody" v-if="profile">
            <div v-if="profile.resultEntity == null" class="market-content">
              <h4>No details found</h4>
            <button class="btn btn-secondary" @click="close">
              close
            </button>
          </div>
          <button class="btn btn-secondary" :class="{ active: option == 'roles'}" @click="changeState('roles')">
           Roles
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'adversemedia'}" @click="changeState('adversemedia')">
           Sources and Adverse Media
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'poi'}" @click="changeState('poi')">
           Profile of Interests
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'linkedIndivid'}" @click="changeState('linkedIndivid')">
            Linked Individuals
          </button>
          <button class="btn btn-secondary" :class="{ active: option == 'linkedCompany'}" @click="changeState('linkedCompany')">
            Linked Companies
          </button>
         
            <div class="market-content" v-if="option == 'roles'">
              <table v-if="profile.resultEntity != null">
                          <thead>
                            <tr>
                              <th>Roles</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                            <td>
                              <table class="inside-table">
                                  <tbody>
                                    <tr v-for="role in profile.resultEntity.roles" :key="role.id">
                                        <td>{{ role.title }}</td>
                                        <td>{{ role.country }}</td>
                                      </tr>
                                  </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                        </table>
                        </div>
                        <div v-if="option == 'linkedIndivid'" class="market-content">
                          <table class="table" v-if="profile.resultEntity != null">
                          <thead>
                            <tr>
                              <th>Linked Individuals</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                            <td>
                              <table class="inside-table">
                                        <tbody>
                                          <tr v-for="name in profile.resultEntity.linkedIndividuals" :key="name.id">
                                            <th>{{ name.firstName }} {{ name.middleName }} {{ name.lastName }}</th>
                                            <th>{{ name.category }}</th>
                                            <th>{{ name.description }}</th>
                                          </tr>
                                        </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                        </table>
                        </div>
                       <div class="market-content"  v-if="option == 'poi'"> 
                    
                        <table class="table" v-if="profile.resultEntity != null">
                          <thead>
                            <tr>
                              <th>Profile of Interests</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                            <td>
                              <table class="inside-table">
                                        <tbody>
                                          <tr v-for="name in profile.resultEntity.profileOfInterests" :key="name.id">
                                            <th>{{ name.category }}</th>
                                            <th>
                                              <table>
                                                <tbody>
                                                  <tr v-for="position in name.positions" :key="position.id">
                                                    <td>{{ position.country }}</td>
                                                    <td>{{ position.position }}</td>
                                                    <td>{{ position.from }}</td>
                                                    <td>{{ position.to }}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </th>
                                          </tr>
                                        </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                        </table>
                       </div>
                      
                      <div class="market-content" v-if="option == 'adversemedia'">
                        <table class="table" v-if="profile.resultEntity != null">
                          <thead>
                            <tr>
                              <th>Sources and Adverse Media</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                            <td>
                              <table class="inside-table">
                                  <tbody>
                                          <tr v-for="item in profile.resultEntity.sources" :key="item.id">
                                            <th>{{ item.categories }}</th>
                                            <th ><a :href="item.url" target="_blank" class="ellipsis-text">{{ item.url }}</a></th>
                                          
                                            <!-- <th>{{ item.dates_Urls }}</th> -->
                                          </tr>
                                  </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  <div class="market-content" v-if="option == 'linkedCompany'">
                        <table class="table" v-if="profile.resultEntity != null">
                          <thead>
                            <tr>
                              <th>Linked Companies</th>
                            </tr>
                          </thead>
                          <tbody >
                            <tr>
                            <td>
                              <table class="inside-table">
                                  <tbody>
                                          <tr v-for="item in profile.resultEntity.linkedCompanies" :key="item.id">
                                            <th >{{ item.category }}</th>
                                            <th>{{ item.name }}</th>
                                            <th>{{ item.description }}</th>
                                            <!-- <th>{{ item.dates_Urls }}</th> -->
                                          </tr>
                                  </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                    </table>
                  </div>
                  
                       
           
             
           
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import moment from 'moment';
  
  export default {
      props:["profile", "webSearchResults"],
      name: 'NamescreenResult',
      components: {
      },
      
       data: function() {
      return { 
       isLoading: false,
       showComment: false,
       showAdd: false,
       option: 'roles',
      
      }
      },
      computed:{
        show() {
          return this.$store.state.show;
        },
        init() {
            return this.$store.state.init;
        },
      
     
      },
      methods:{
        changeState(option) {
            this.option = option;
            localStorage.setItem("option", option);
          },
        getScanHistory(){
            const options = {
              method: 'GET',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
              url: ' /ax/namescan/history'
              }
              this.axios(options)
              .then((response) => {
                this.scanHistory = response.data
                this.scanHistory.forEach((el,i) => {
                 
                })
              }).catch((err) => {
                  
              })
        },
       
       
        closeModal(){
          this.$emit("close")
        },
        close(){
          window.location.reload();
        },
          
        formatDate: function(date) {
        return moment(new Date(date)).format('MM/DD/YYYY hh:mm:ss A');
        },
          
          updateDate() {
          if (this.day && this.month && this.year) {
            // Do any additional validation or formatting if needed
            const formattedDate = `${this.day.toString().padStart(2, '0')} ${this.month.toString().padStart(2, '0')} ${this.year.toString()}`;
            console.log('Selected Date:', formattedDate);
          }
        },
           
          toggleCountryListForAddress(){
            this.showCountryForAddress = !this.showCountryForAddress;
          },
          selectCountryForAddress: function(c) {
            this.showCountryForAddress = false;
            this.acountry = c.countryCode
           
          },
          openSettings(){
          this.showSettings = !this.showSettings
        },
              
          getMobileCodes: function() {
          this.axios.get('/assets/js/mobilecodes.json')
              .then((response) => {
              this.list.mobileCodes = response.data;
              }).catch((err) => {
              console.log(err);

              })
          },
        
          sessionCheck() {
            const options = {
              method: 'POST',
              headers: {
                  'content-type': 'application/x-www-form-urlencoded',
                  'vmiadmin-authz': localStorage.getItem("tokenId") 
              }, 
            
              url: '/ax/sessionCheck',
              }
            this.axios(options)
              .then((response) => {
             
              }).catch((err) => {
                  if(err.response.data.msgCode == '00001') {
                      window.location.href = "/#/login";
                  }  
              })
          },

      },
      mounted(){
          this.sessionCheck();
          this.getScanHistory();
      }
  }
</script>
  
  <style scoped lang=scss>
  .backdrop {
      top: 0;
      right:0;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 98;
      background: rgba(0, 0, 0, 0.6);
  
  }
  .b-modal {
      width: 1000px;
      height: auto;
      margin: 20px auto;
      background: white;
      border-radius: 20px;
      border-color:white;
      border-width:2px;
      z-index: 99;
      max-height: calc(100vh - 110px);
      overflow-y: auto;
      overflow-x: hidden;
      color:black;
  }

 
.ellipsis-text {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  padding: 20px;
  font-size: 10px;
  margin: 0;
  resize: horizontal;
    }

  .modal-header {
      background-color: white;
      margin-right:0px;
      font-size:25px;
      display:flex;
      justify-content:space-between;
      width: 1000px;
  }
  
  .modal-title{
      margin: 0 auto;
      color:white;
  }
  .market-content {
        width: 100%;
        border-radius: 10px;
        padding: 0;
        table {
          width: 100%;
          thead {
            th {
              font-size: 15px;
              color: #c5c2c2; 
              letter-spacing: 0.65px;
              padding: 1em;
              position: sticky;
              top: 0;
              background-image: linear-gradient(
              to bottom,
              #5a5f71 29%,
              #10121C 88%
        );
              text-align: inherit;
            }
          }
    
          tbody {
            background-image: linear-gradient(
              to bottom,
              #202538 29%,
              #10121C 88%
        );
            td {
              color: #FFFFFF;
              letter-spacing: 0.65px;
              padding: 1em;
    
              a {
                color: #FFFFFF;
                text-decoration: none;
    
                &:hover {
                  text-decoration: underline;
                }  
              }
    
              &.hash {
                a {
                  color: #FFBE21;
                }
                
              }
            }
          }
        }
      }
  .modalBody {
      background-color:white;
      width:100%;
      padding:5px;
      border-radius: 10px;;
  }
  

   .form-group{
          width: 100%;
      }
  
      .btn-main{
          margin: 30px auto;
          border: 1px solid #481a69;
          width: 100%;
      }
      .btn-main:hover{
          background-color:#481a69;
          color: white;
      }
  
  
      @media screen and (max-width: 1100px) {
       .body {
          height:auto;
          margin: 0 auto;
        }
       .heading {
           font-size:25px;
        }
  
        .subheading {
          font-size:12px;
        }
            
      }
     @media screen and (max-width: 800px) {
       .body {
          height:auto;
          margin: 0 auto;
        }
        .heading {
           font-size:25px;
        }
  
      .subheading {
        font-size:12px;
      }
      .card {
        width:15rem;
        height:21rem;
        font-size:10px;
      }
          
    }
  
      @media screen and (max-width: 600px) {
            .firstThree {
               margin: 0 auto;
               display: flex;
               justify-content: center;
          }
           .card {
              width:12rem;
              height:16rem;
              padding-bottom: 10px;
            }
            .card h6{
              font-size: 10px !important;
            }
          
      }
  
      @media screen and (max-width: 420px) {
  
         .b-modal {
              width: 400px;
              height: auto;
              margin-top: 50px;
              background: white;
              border-radius: 20px;
              border-color:white;
              border-width:2px;
              z-index: 1;
          }
  
          .modalBody {
              background-color:white;
              width:100%;
              padding: 20px 0px;
              border-radius: 10px;;
          }
          .submit {
              background-color:rgb(255, 102, 0);
              color:white;
              font-family:'PoppinsMedium', sans-serif;
              width:250px;
              margin-right:10px;
              margin-left:60px;
          }
  
          .cancel {
              color:#191919;
              background-color:lightgray;
              font-family:'PoppinsMedium', sans-serif;
              width:250px;
              margin-right:10px;
              margin-left:60px;
          }
  
          .textField {
              width:100px;
              padding:5px;
              border:none;
              color:#191919;
              background-color:lightgray;
              border-radius:3px;
              font-family: 'PoppinsRegular', sans-serif;
          }
  
          .checkboxGroup {
              width:300px;
              margin:0;
          }
  
          .sendCode {
              font-size:12px;
              margin-top: 5px;
              text-align:right;
              width:250px;
              font-weight:bold;
              cursor:pointer;
          }
  
          #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
              width:40px;
              height:80px;
              padding:0;
              border:none;
              color:#191919;
              background-color:lightgray;
              border-radius:3px;
              font-family: 'PoppinsRegular', sans-serif;
              font-size:30px;
              margin:2px;
              display: flex !important;
          }
  
          #mobile1, #mobile2, #mobile3, #mobile4, #mobile5, #mobile6, #mobile7, #mobile8, #mobile9, #mobile10 {
              width:20px;
              padding:5px;
              border:none;
              color:#191919;
              background-color:lightgray;
              border-radius:3px;
              font-family: 'PoppinsRegular', sans-serif;
              margin:1px;
          }
  
          .otpLabel {
              font-size:15px;
              width:300px;
              padding:10px;
          }
  
          .checkWrapper {
              display: block;
              justify-content: flex-start;
              width:100%;
              margin-left:20px;
          }
  
          .buttonWrapper {
              display: block;
              justify-content: center;
              width:100%;
          }
  
      }
  
      @media screen and (max-width: 380px) {
          .b-modal {
                  width: 315px;
          }
  
          #pin1, #pin2, #pin3, #pin4, #pin5, #pin6 {
              width:33px;
          }
  
          #areaCode h3{
              font-size:1px !important;
          }
  
          .mobileWrapper {
              display: flex;
              justify-content: flex-start;
              width:100%;
          }
  
          .formWrapper {
              display: flex;
              justify-content: flex-start;
              width:100%;
          }
  
          .checkWrapper {
              display: block;
              justify-content: flex-start;
              width:100%;
          }
  
          .buttonWrapper {
              display: block;
              justify-content: flex-start;
              width:100%;
              margin-left:0px;
          }
  
          .checkWrapper {
              display: block;
              justify-content: flex-start;
              width:100%;
              margin-left:0px;
          }
  
          .msgCon {
              width:300px;
          }
  
          .submit {
              background-color:rgb(255, 102, 0);
              color:white;
              font-family:'PoppinsMedium', sans-serif;
              width:250px;
              margin-right:10px;
              margin-left:20px;
          }
  
          .cancel {
              color:#191919;
              background-color:lightgray;
              font-family:'PoppinsMedium', sans-serif;
              width:250px;
              margin-right:10px;
              margin-left:20px;
          }
          
      }
      .loading-spinner {
        border: 4px solid rgba(0, 0, 0, 0.3);
        border-top: 4px solid #007BFF; /* Change the color to your preferred color */
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
        margin: 0 auto;
      }
      @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>